// export default function handleDarkMode() {
// Constants
const storageKey = "theme";
const lightThemeClass = "light-theme";
const darkThemeClass = "dark-theme";
const btnTextClass = "btn__color-theme__text";
const themeSelectorId = "color-theme-selector";

const navbrandImg = jQuery(
  ".header__megamenu-extended .navbar__megamenu-extended .navbar__megamenu__header .navbar-brand img"
);

const footerbrandImg = jQuery("footer .logo img");
const footerSubbrandImg = jQuery("footer .logo img[data-defaultSubLogo]");
const favicon = jQuery("link[rel='icon']");

// Get Theme selector button
const themeSelector = document.getElementById(themeSelectorId);
// const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');

// Get system theme preference.
const isDarkSystemTheme =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

// Get the theme preference (dark-theme, light-theme or if it's not there: null)
const getSelectedTheme = () => localStorage.getItem(storageKey);

// Set theme helper
const setSelectedTheme = (theme) => localStorage.setItem(storageKey, theme);

// sets the theme to (1) dark-theme or (2) light-theme
const setDarkModeLocalStorage = () => setSelectedTheme(darkThemeClass);
const setLightModeLocalStorage = () => setSelectedTheme(lightThemeClass);

// toggles the controling classes to the body - dark-theme or light-theme
const setDarkModeClasses = () => {
  document.body.classList.add(darkThemeClass);
  document.body.classList.remove(lightThemeClass);

  if (jQuery(themeSelector).length > 0) {
    themeSelector.setAttribute('aria-label', 'Deaktiver darkmode')
	  themeSelector.setAttribute('title', 'Deaktiver darkmode')
  }

  if (document.getElementsByClassName(btnTextClass).length > 0) {
    changeBtnText();
  }
};

const setLightModeClasses = () => {
  document.body.classList.add(lightThemeClass);
  document.body.classList.remove(darkThemeClass);

  if (jQuery(themeSelector).length > 0) {
    themeSelector.setAttribute('aria-label', 'Aktiver darkmode')
	  themeSelector.setAttribute('title', 'Aktiver darkmode')
  }

  if (document.getElementsByClassName(btnTextClass).length > 0) {
    changeBtnText();
  }
};


function changeBtnText() {
  const targetDiv = document.getElementById(themeSelectorId).getElementsByClassName(btnTextClass)[0];
  const active = document.getElementById(themeSelectorId).getAttribute("data-inactive");
  const inactive = document.getElementById(themeSelectorId).getAttribute("data-active");

  if (document.body.classList.contains(darkThemeClass)) {
    targetDiv.textContent = inactive;
  } else {
    targetDiv.textContent = active;
  }
}

function setDefaultLogo() {
  const defaultNavLogo = navbrandImg.attr("data-defaultLogo");
  navbrandImg.attr("data-src", defaultNavLogo);
  navbrandImg.attr("src", defaultNavLogo);

  const defaultFooterLogo = footerbrandImg.attr("data-defaultLogo");
  footerbrandImg.attr("data-src", defaultFooterLogo);
  footerbrandImg.attr("src", defaultFooterLogo);

  const subFooterLogo = footerSubbrandImg.attr("data-defaultSubLogo");
  footerSubbrandImg.attr("data-src", subFooterLogo);
  footerSubbrandImg.attr("src", subFooterLogo);
}

function setDarkomdeLogo() {
  const darkmodeNavLogo = navbrandImg.attr("data-darkmodeLogo");
  navbrandImg.attr("data-src", darkmodeNavLogo);
  navbrandImg.attr("src", darkmodeNavLogo);

  const darkmodeFooterLogo = footerbrandImg.attr("data-darkmodeLogo");
  footerbrandImg.attr("data-src", darkmodeFooterLogo);
  footerbrandImg.attr("src", darkmodeFooterLogo);

  const darkmodeSubFooterLogo = footerSubbrandImg.attr("data-darkmodeSubLogo");
  footerSubbrandImg.attr("data-src", darkmodeSubFooterLogo);
  footerSubbrandImg.attr("src", darkmodeSubFooterLogo);
}

setDarkomdeFavicon();

function setDarkomdeFavicon() {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    const darkmodeFavicon = favicon.attr("data-darkmode");
    favicon.attr("href", darkmodeFavicon);
    setDarkomdeLogo();
  } else {
    const defaultFavicon = favicon.attr("data-default");
    favicon.attr("href", defaultFavicon);
  }
}

// Handle system theme preference
const handleSystemThemePreferenceMount = () => {
  isDarkSystemTheme ? setDarkModeClasses() : setLightModeClasses();
};

// Handle user selection on click
const handleThemeSelection = () => {
  const selectedTheme = getSelectedTheme();

  // fist time the user sets a preference, the theme-classes toggles based on the system theme preference
  if (!selectedTheme) {
    if (isDarkSystemTheme) {
      setLightModeClasses();
      setLightModeLocalStorage();
      setDefaultLogo();
    } else {
      setDarkModeClasses();
      setDarkModeLocalStorage();
      setDarkomdeLogo();
    }

    return;
  }

  // if the user changes the preference, the theme-classes toggles based on the theme value
  if (selectedTheme === darkThemeClass) {
    setLightModeClasses();
    setLightModeLocalStorage();
    setDefaultLogo();
  } else {
    setDarkModeClasses();
    setDarkModeLocalStorage();
    setDarkomdeLogo();
  }
};

// set the classes based on the latets preference
const handleThemeMount = () => {
  const isDarkMode = getSelectedTheme() === darkThemeClass;

  if (isDarkMode) {
    setDarkModeClasses();
    setDarkModeLocalStorage;
    setDarkomdeLogo();
  } else {
    setLightModeClasses();
    setLightModeLocalStorage();
    setDefaultLogo();
  }
};

// Code to run on component mount
const onMount = () => {
  const selectedTheme = getSelectedTheme();

  // theme on previous preferene or if null, the system theme preference.
  selectedTheme ? handleThemeMount() : handleSystemThemePreferenceMount();

  // Setup event listener
  themeSelector && themeSelector.addEventListener("click", handleThemeSelection);
};

onMount();
